import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import ChecklistResourcesSection from "../../../components/ChecklistResourcesSection/ChecklistResourcesSection.jsx";
import { checklists } from "../../../layout/Constant.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ChecklistResourcesSection figmaUrl={checklists.componentCheckbox.figmaUrl.ios} codeUrl={checklists.componentCheckbox.codeUrl.ios} checklists={checklists.componentCheckbox.checklists} mdxType="ChecklistResourcesSection" />
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`Checkbox is a square box that is ticked (checked) when activated.`}</p>
    <p>{`Use Legion Checkbox styles to modify checkbox with lots of attributes that make you easier.`}</p>
    <h3>{`Usage With Theme`}</h3>
    <p><strong parentName="p">{`Available themes: ThemeAGR, ThemeMyTEnS, ThemeIHS & ThemeEazy`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNCheckbox();
`}</code></pre>
    <h3>{`Usage Without Theme`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`Checkbox();
`}</code></pre>
    <div className="divi" />
    <h2>{`Variant`}</h2>
    <p>{`Legion Have 2 Type of Checkbox :`}</p>
    <p>{`Use this Function : `}<inlineCode parentName="p">{`LGNCheckbox`}</inlineCode>{` and add value `}<inlineCode parentName="p">{`defaultIsChecked:`}</inlineCode>{` `}<inlineCode parentName="p">{`true`}</inlineCode>{` or `}<inlineCode parentName="p">{`false`}</inlineCode>{`
This code sample demonstrates how to modify the variant of checkbox :`}</p>
    <p>{`A. Checked ON`}</p>
    <div {...{
      "className": "item-one"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/checkbox/ios-checkbox-ischecked.png"
      }}></img>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNCheckbox(
  defaultIsChecked: true
)
`}</code></pre>
    <p>{`B. Checked OFF`}</p>
    <div {...{
      "className": "item-one"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/checkbox/ios-checkbox-ischecked-off.png"
      }}></img>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNCheckbox(
  defaultIsChecked: false
)
`}</code></pre>
    <div className="divi" />
    <h2>{`Attribute`}</h2>
    <p>{`Legion Have 8 Attributes for Customize Checkbox :`}</p>
    <h3><strong parentName="h3">{` Size `}</strong></h3>
    <div {...{
      "className": "item-one"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/checkbox/ios-checkbox-ischecked.png"
      }}></img>
    </div>
    <p>{`This size checkbox attribute for user to choose size of checkbox.`}</p>
    <p>{`Checkbox have 3 size `}<inlineCode parentName="p">{`.small`}</inlineCode>{`, `}<inlineCode parentName="p">{`.medium`}</inlineCode>{` and `}<inlineCode parentName="p">{`.large`}</inlineCode></p>
    <p>{`This code sample demonstrates how to modify size checkbox :`}</p>
    <p>{`A. Small`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNCheckbox(
  defaultIsChecked: true,
  label: "Label",
  size: .small
)
`}</code></pre>
    <p>{`B. Medium`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNCheckbox(
  defaultIsChecked: true,
  label: "Label",
  size: .medium
)
`}</code></pre>
    <p>{`C. Large`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNCheckbox(
  defaultIsChecked: true,
  label: "Label",
  size: .large
)
`}</code></pre>
    <h3><strong parentName="h3">{` Disable `}</strong></h3>
    <p>{`Choose type of checkbox if `}<strong parentName="p">{`Checked`}</strong>{` `}<inlineCode parentName="p">{`defaultIsChecked: true`}</inlineCode>{` and `}<strong parentName="p">{`Not Checked`}</strong>{` `}<inlineCode parentName="p">{`defaultIsChecked: false`}</inlineCode>{` Choose size checkbox : For `}<strong parentName="p">{`Large`}</strong>{` size add `}<inlineCode parentName="p">{`.large`}</inlineCode>{`, for `}<strong parentName="p">{`Medium`}</strong>{` size add `}<inlineCode parentName="p">{`.medium`}</inlineCode>{`, and for `}<strong parentName="p">{`Small`}</strong>{` size add `}<inlineCode parentName="p">{`.small`}</inlineCode>{`, Last finish it if the checkbox `}<strong parentName="p">{`Disable`}</strong>{` with add this funcition `}<inlineCode parentName="p">{`isDisabled: Bool`}</inlineCode>{`
where `}<inlineCode parentName="p">{`Bool`}</inlineCode>{` consist of `}<inlineCode parentName="p">{`true`}</inlineCode>{` or `}<inlineCode parentName="p">{`false`}</inlineCode></p>
    <p>{`This code sample demonstrates how to modify disable of the checkbox :`}</p>
    <p><strong parentName="p">{` Checked ON `}</strong></p>
    <div {...{
      "className": "item-one"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/checkbox/ios-checkbox-ischecked-isdisabled.png"
      }}></img>
    </div>
    <p>{`A. Checked ON, Size Large, Disable ON`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNCheckbox(
  defaultIsChecked: true,
  label: "Label",
  size: .large,
  isDisabled: true
)
`}</code></pre>
    <p>{`B. Checked ON, Size Medium, Disable ON`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNCheckbox(
  defaultIsChecked: true,
  label: "Label",
  size: .medium,
  isDisabled: true
)
`}</code></pre>
    <p>{`C. Checked ON, Size small, Disable ON`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNCheckbox(
  defaultIsChecked: true,
  label: "Label",
  size: .small,
  isDisabled: true
)
`}</code></pre>
    <p><strong parentName="p">{` Checked OFF `}</strong></p>
    <div {...{
      "className": "item-one"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/checkbox/ios-checkbox-ischecked-off-isdisabled.png"
      }}></img>
    </div>
    <p>{`A. Checked OFF, Size Large, Disable ON`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNCheckbox(
  defaultIsChecked: false,
  label: "Label",
  size: .large,
  isDisabled: true
)
`}</code></pre>
    <p>{`B. Checked OFF, Size Medium, Disable ON`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNCheckbox(
  defaultIsChecked: false,
  label: "Label",
  size: .medium,
  isDisabled: true
)
`}</code></pre>
    <p>{`C. Checked OFF, Size small, Disable ON`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNCheckbox(
  defaultIsChecked: false,
  label: "Label",
  size: .small,
  isDisabled: true
)
`}</code></pre>
    <h3><strong parentName="h3">{` Label `}</strong></h3>
    <div {...{
      "className": "item-one"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/checkbox/ios-checkbox-ischecked.png"
      }}></img>
    </div>
    <p>{`Choose type of checkbox if `}<strong parentName="p">{`Checked ON`}</strong>{` `}<inlineCode parentName="p">{`defaultIsChecked: true`}</inlineCode>{` or `}<strong parentName="p">{`Checked OFF`}</strong>{` `}<inlineCode parentName="p">{`defaultIsChecked: false`}</inlineCode>{` Add `}<strong parentName="p">{`Label`}</strong>{` with `}<inlineCode parentName="p">{`label: "Value Label"`}</inlineCode>{`, Next Choose size checkbox : For `}<strong parentName="p">{`Large`}</strong>{` size add `}<inlineCode parentName="p">{`.large`}</inlineCode>{`, for `}<strong parentName="p">{`Medium`}</strong>{` size add `}<inlineCode parentName="p">{`.medium`}</inlineCode>{`, and for `}<strong parentName="p">{`Small`}</strong>{` size add `}<inlineCode parentName="p">{`.small`}</inlineCode>{`.`}</p>
    <p>{`This code sample demonstrates how to modify label of the checkbox :`}</p>
    <p>{`A. Checked ON, Size Large`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNCheckbox(
  defaultIsChecked: true,
  label: "Label",
  size: .large
)
`}</code></pre>
    <p>{`B. Checked ON, Size Medium`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNCheckbox(
  defaultIsChecked: true,
  label: "Label",
  size: .medium
)
`}</code></pre>
    <p>{`C. Checked ON, Size Small`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNCheckbox(
  defaultIsChecked: true,
  label: "Label",
  size: .small
)
`}</code></pre>
    <h3><strong parentName="h3">{` Mixed `}</strong></h3>
    <div {...{
      "className": "item-one"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/checkbox/ios-checkbox-ismixed.png"
      }}></img>
    </div>
    <p>{`Choose type of checkbox if `}<strong parentName="p">{`Checked ON`}</strong>{` `}<inlineCode parentName="p">{`defaultIsChecked: true`}</inlineCode>{` or `}<strong parentName="p">{`Checked OFF`}</strong>{` `}<inlineCode parentName="p">{`defaultIsChecked: false`}</inlineCode>{`, Add `}<strong parentName="p">{`Label`}</strong>{` with `}<inlineCode parentName="p">{`label: "Value Label"`}</inlineCode>{`, Next Choose size checkbox : For `}<strong parentName="p">{`Large`}</strong>{` size add `}<inlineCode parentName="p">{`.large`}</inlineCode>{`, for `}<strong parentName="p">{`Medium`}</strong>{` size add `}<inlineCode parentName="p">{`.medium`}</inlineCode>{`, and for `}<strong parentName="p">{`Small`}</strong>{` size add `}<inlineCode parentName="p">{`.small`}</inlineCode>{`.
Last Finish with `}<strong parentName="p">{`Mixed`}</strong>{` add `}<inlineCode parentName="p">{`isMixed: true`}</inlineCode></p>
    <p>{`This code sample demonstrates how to modify mixed of the checkbox :`}</p>
    <p>{`A. Mixed, Size Large, with Label`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNCheckbox(
  defaultIsChecked: true,
  label: "Label",
  size: .large,
  isMixed: true
)
`}</code></pre>
    <p>{`B. Mixed, Size Medium, with Label`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNCheckbox(
  defaultIsChecked: true,
  label: "Label",
  size: .medium,
  isMixed: true
)
`}</code></pre>
    <p>{`C. Mixed, Size Small, with Label`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`LGNCheckbox(
  defaultIsChecked: true,
  label: "Label",
  size: .small,
  isMixed: true
)
`}</code></pre>
    <h3><strong parentName="h3">{` Background `}</strong></h3>
    <div {...{
      "className": "item-one"
    }}>{`
  `}<img parentName="div" {...{
        "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/checkbox/ios-checkbox-iscustbg.png"
      }}></img>
    </div>
    <p>{`This code sample demonstrates how to modify the Background Checkbox Custom :`}</p>
    <p>{`A. Background Checked ON, Size Large & Disabled OFF`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`var defaultCheckboxStyle: CheckboxStyle {
  var style = DefaultCheckboxStyle()
  style.colorBgCheckedOnDisabledOff = .orange
  style.colorBgCheckedOffDisabledOff = .orange
  style.colorBorderCheckedOn = .black
  style.textColor = .blue
  return style
}

LGNCheckbox(
  defaultIsChecked: true,
  label: "Label",
  size: .large
)
.checkboxStyle(defaultCheckboxStyle)
.setColorIconCheckedOn(color: .red)
`}</code></pre>
    <p>{`B. Background Checked ON, Size Medium & Disabled OFF`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`var defaultCheckboxStyle: CheckboxStyle {
  var style = DefaultCheckboxStyle()
  style.colorBgCheckedOnDisabledOff = .orange
  style.colorBgCheckedOffDisabledOff = .orange
  style.colorBorderCheckedOn = .black
  style.textColor = .blue
  return style
}

LGNCheckbox(
  defaultIsChecked: true,
  label: "Label",
  size: .medium
)
.checkboxStyle(defaultCheckboxStyle)
.setColorIconCheckedOn(color: .red)
`}</code></pre>
    <p>{`C. Background Checked ON, Size Small & Disabled OFF`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`var defaultCheckboxStyle: CheckboxStyle {
  var style = DefaultCheckboxStyle()
  style.colorBgCheckedOnDisabledOff = .orange
  style.colorBgCheckedOffDisabledOff = .orange
  style.colorBorderCheckedOn = .black
  style.textColor = .blue
  return style
}

LGNCheckbox(
  defaultIsChecked: true,
  label: "Label",
  size: .small
)
.checkboxStyle(defaultCheckboxStyle)
.setColorIconCheckedOn(color: .red)
`}</code></pre>
    <div className="divi" />
    <h2>{`Properties`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Properties`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Description`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Default Value`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`defaultIsChecked`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The boolean to set the current value of the checkbox. Whether it is checked on (`}<inlineCode parentName="td">{`true`}</inlineCode>{`) or off (`}<inlineCode parentName="td">{`false`}</inlineCode>{`)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`label`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The label for the checkbox that describes it. Default value is nil.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`""`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`fontFamily`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A string of font family name. Default value is empty string.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`nil`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`textForegroundColor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A color to be used for the text label on the right, when the view is not disabled. Default value is colorl tertiary800.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Color.LGNTheme.tertiary800`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`textDisabledColor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A color to be used for the text label on the right, when the view is disabled. Default value is color tertiary500.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Color.LGNTheme.tertiary500`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`size`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The size of the checkbox. Either `}<inlineCode parentName="td">{`.default`}</inlineCode>{` or `}<inlineCode parentName="td">{`.small`}</inlineCode>{` Default is `}<inlineCode parentName="td">{`.default`}</inlineCode>{`.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.default`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`isDisabled`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A Boolean binding state value that determines whether user can interact with this view. Default is false.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.constant(false)`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`isMixed`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A Boolean value that determines the icon of the checkbox when is checked true, when isMixed is true, the icon of checkbox will be minus icon. Default is false.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.constant(false)`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`onToggleChange`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The action to perform when user toggles the checkbox. Default is nil.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`nil`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <div className="divi" />
    <h2>{`List of viewModifier`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`ViewModifier`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Description`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Parameters`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`setBorderWidth`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Change the border width of the checkbox.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`width`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`setColorIconCheckedOn`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Change the icon color of the checkbox when checked on.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`color`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`animateBounceWhenCheckedOn`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`This function will bouncing the icon inside checkbox when changing to checked on`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`isActive`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`variantSize`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Change the size of the Checkbox with large size as default`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`size`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`checkboxStyle`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set the style for checkbox within this view to a CheckboxStyle with a custom appearance.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`style`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      